import React, { useEffect } from 'react';
import { components } from 'ComponentRenderer.js';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import {
  Container,
  Content2Xl,
  ContentWithVerticalPadding,
} from 'components/misc/Layouts';
import tw from 'twin.macro';
import { LogoLink } from 'components/headers/light.js';
import { SectionHeading as HeadingBase } from 'components/misc/Headings';
import { SectionDescription as DescriptionBase } from 'components/misc/Typography';
import logo from 'images/mrhf-logo.svg';
import styled from 'styled-components';
import heroImg from 'images/MRHF/MRHF2.jpg';
import MainFeature from 'components/features/TwoColWithButton.js';
import emergencyImg from 'images/MRHF/MRHF7.jpg';
import nutritionImg from 'images/MRHF/hero-min.jpg';
import environmentImg from 'images/MRHF/MRHF8.jpg';
import MRHF4 from 'images/MRHF/MRHF4.jpg';
import MRHF5 from 'images/MRHF/MRHF5.jpg';
import MRHF6 from 'images/MRHF/MRHF11.jpg';
import MRHF7 from 'images/MRHF/MRHF1.jpg';
import MRHF8 from 'images/MRHF/MRHF3.jpg';
import MRHF9 from 'images/MRHF/MRHF9.jpg';
import MRHF10 from 'images/MRHF/MRHF10.jpg';
import MRHF11 from 'images/MRHF/MRHF6.jpg';

/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between `;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 md:mr-12 mr-4 text-gray-700 border-gray-400 hocus:border-gray-700 sm:text-xs md:text-base`;
const PrimaryNavLink = tw(
  NavLink,
)`text-gray-100 bg-primary-500 px-12 md:px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline mt-6 md:mt-4 lg:mt-0 `;
const SectionContainer = tw(ContentWithVerticalPadding)`pb-0`;
const SectionHeading = tw(HeadingBase)`text-primary-900`;

const SectionDescription = tw(
  DescriptionBase,
)` mx-auto text-gray-600 max-w-4xl`;

const Contact = tw(
  SectionDescription,
)`pt-10 w-full sm:w-full lg:w-1/3 mx-auto text-center text-gray-800 max-w-4xl`;

export default ({
  features = null,
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  blocks = components.blocks,
  description = '',
}) => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  useEffect(() => {
    window.gtag('js', new Date());
    window.gtag('config', 'UA-45799926-9');
  }, []);

  const noOfLandingPages = Object.keys(landingPages).length;
  const noOfInnerPages = Object.keys(innerPages).length;
  const noOfComponentBlocks = Object.values(blocks).reduce(
    (acc, block) => acc + Object.keys(block.elements).length,
    0,
  );

  features = features || [
    `${noOfLandingPages} Landing Page Demos`,
    `${noOfInnerPages} Inner Pages`,
    `${noOfComponentBlocks} Components`,
    'Uses TailwindCSS',
    'Fully Responsive',
    'Fully Customizable',
  ];

  /**
   * Custom
   */

  const ThreeColumnContainer = styled.div`
    ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center pb-0`}
  `;

  const Column = styled.div`
    ${tw`md:w-1/2 lg:w-1/3  flex`}
  `;

  const Card = styled.div`
    ${tw`flex flex-col mx-auto items-center px-6 py-10`}
    .imageContainer {
      img {
        ${tw`w-auto`}
      }
    }
  `;
  const Wrap = styled.div`
    ${tw`flex flex-wrap justify-center lg:justify-end items-center sm:mx-auto my-4 md:-mr-12 `}
  `;

  return (
    <AnimationRevealPage disabled>
      <Container>
        <Content2Xl>
          <NavRow>
            <LogoLink href='/'>
              <img src={logo} alt='Minnedosa Regional Health Foundation Logo' />
            </LogoLink>
            <Wrap>
              <NavLink href='#about'>About MRHF</NavLink>
              <NavLink href='#hospital-content'>Hospital Services</NavLink>
              <NavLink href='#contact'>Contact</NavLink>
              <PrimaryNavLink href='#donate' className='.zeffy-btn'>
                Donate Now
              </PrimaryNavLink>
            </Wrap>
          </NavRow>

          <SectionContainer id='about'>
            <SectionHeading>
              Minnedosa Regional Health Foundation
            </SectionHeading>

            <MainFeature
              subheading=''
              heading='About MRHF'
              description='The Foundation is responsible for funding the needs of the
              hospital when there is a money shortfall. Fundraising for the hospital is a crucial part of
              continuing good care. The Foundation exists to distribute funds from wills, bequeathed amounts and donations to maintain 
              any fundraising efforts for the Hospital. This money is used separately from the working capital of 
              the hospital. This allows your funds to specifically fill the list of items needed from the doctors, 
              nurses and the Care Team Manager at the Hospital.'
              buttonRounded={false}
              textOnLeft={true}
              primaryButtonText=''
              imageSrc={MRHF11}
              imageCss={false}
              imageDecoratorBlob={true}
              imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
            />

            <MainFeature
              subheading=''
              heading='Equipment Funding'
              description='The Hospital Foundation has funded over $750,000 in equipment so that we, can continue to enjoy 
              hospital with great equipment. In 2023 alone the Foundation funded $70,000 in equipment which included:
              Prime big wheel stretcher, arthritic shoulder repair set, suture cutter open ended, two medical carts,
              two vital sign machines and a syringe pump.'
              buttonRounded={false}
              textOnLeft={false}
              primaryButtonText=''
              imageSrc={MRHF7}
              imageCss={false}
              imageDecoratorBlob={true}
              imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
            />

            <SectionDescription>
            The Foundation is starting a campaign to increase our ability to purchase more equipment for the hospital. There is a list of equipment presented to us for items to ensure all the equipment needs are met.
              There is a list presented to us with for funding items that the
              nurses and care team managers have determined to be most
              important. To date the list would take another $200,000 to
              complete.
              <br />
              Our ongoing fundraising campaign is asking for your donations. We
              know that everybody has their hand out looking for funding for all
              kinds of special projects. We know. <br />
              <br /> Our intention is to raise funds for our hospital and of
              course we do issue tax receipts. If you or someone you know can
              give to this effort, we very much appreciate it. We are also able
              to accept donations from charitable gift in your will (while still
              supporting your family.) <br /> <br />
              Our beautiful Minnedosa Hospital could be something to consider
              leaving a small portion of your estate to. Let’s say you leave 1%
              to the Hospital Foundation. You get a nice tax benefit, and your
              family still gets 99% of all you have worked and saved for.
              <br /> <br />
              Every donation makes a big difference to the Hospital Foundation.
              Leaving a charitable gift is another of the many ways to leave
              your mark. If you want to put a portion of your donation funding
              towards The Minnedosa Hospital, all will be appreciated. You can donate funds through this website, or you can mail them to:
              <br />
              <b>
                Minnedosa Regional Health Foundation
                <br />
                Box 772
                <br />
                Minnedosa MB
                <br />
                R0J 1E0
              </b>
              <br />
              <br />
              From there the needed equipment will be purchased under the scrutiny of the Care Team Manager. We all want our equipment at the hospital to be in
              excellent working order, upgraded or replaced. Your gift will
              help.
              <br /> <br />
            </SectionDescription>
          </SectionContainer>

          <Content2Xl id='hospital-content'>
            <SectionHeading>
              Minnedosa Regional Health Foundation
            </SectionHeading>
            <MainFeature
              subheading=''
              heading='Hospital History'
              description='The Hospital was built in 1991. Minnedosa and District Hospital
              operates under Prairie Mountain Health. Prairie Mountain Health is an integration
              of health services comprised of three main areas: Hospital,
              Community Health Services and Continuing Care. The Minnedosa and
              District Health Center is located in the Southeast Corner of the
              Town of Minnedosa and offers 27 acute care beds, general surgery,
              diagnostic services, emergency medical services, public health,
              mental health, home care and a respite program.'
              buttonRounded={false}
              textOnLeft={false}
              primaryButtonText=''
              imageSrc={MRHF6}
              imageCss={false}
              imageDecoratorBlob={true}
              imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
            />

            <MainFeature
              subheading=''
              heading='Hospital Services'
              description='Five Family Physicians, Two Nurse Practitioners, Seniors Adult Day Program, Palliative Care Program, Occupational & Physiotherapy Services, Dietician Services, Pharmacy Services for Patients and PCH Residents and Meals on Wheels'
              buttonRounded={false}
              textOnLeft={true}
              primaryButtonText=''
              imageSrc={MRHF7}
              imageCss={false}
              imageDecoratorBlob={true}
              imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
            />

            <MainFeature
              subheading=''
              heading='Emergency Services'
              description='Ambulance and Paramedic Services are provided on a full-time basis by three ambulance units,
              located at the Minnedosa Health Unit. They also provide emergency Services and public
              education in the area of disaster planning and injury prevention.
              For Emergency Services dial 9-1-1'
              buttonRounded={false}
              textOnLeft={false}
              primaryButtonText=''
              imageSrc={emergencyImg}
              imageCss={false}
              imageDecoratorBlob={true}
              imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
            />

            <MainFeature
              subheading=''
              heading='Nutrition Services'
              description='The Nutrition Services team consists of cooks and dietary aides. They are committed to providing safe, nutritious and high-quality meals to the patients, residents, staff and visitors at our facilities.
              We follow a regional menu consisting of a 28 day cycle and accommodate a variety of diets/preferences based on eating well with Canada’s Food Guide.
              Nutrition is a very important part of each person’s overall health, healing and quality of life.'
              buttonRounded={false}
              textOnLeft={true}
              primaryButtonText=''
              imageSrc={nutritionImg}
              imageCss={false}
              imageDecoratorBlob={true}
              imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
            />

            <MainFeature
              subheading=''
              heading='Environmental Services'
              description="Environmental Services' staff play a vital role in ensuring a safe and healthy environment for patients, staff and visitors in our healthcare facilities. They are responsible for maintaining a clean and sanitized environment by following strict guidelines and protocols."
              buttonRounded={false}
              textOnLeft={false}
              primaryButtonText=''
              imageSrc={environmentImg}
              imageCss={false}
              imageDecoratorBlob={true}
              imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
            />
          </Content2Xl>

          <SectionContainer id='contact' tw='py-0'>
            <SectionHeading>Contact</SectionHeading>
            <Contact tw='my-5 text-center'>
              <a href='mailto:contact@mrhf.ca'>contact@mrhf.ca</a>
              <br />
              <br />
              Greg Anderson, Director
              <br />
              <a href='tel:204-212-4759'>204 212 4759</a>
              <br />
              <br />
              Minnedosa Regional Health Foundation
              <br />
              Box 772
              <br />
              Minnedosa Manitoba
              <br />
              R0J 1E0
              <br />
            </Contact>
          </SectionContainer>

          <Container>
            <ThreeColumnContainer>
              <Column>
                <Card>
                  <img src={MRHF8} alt='MRHF' />
                </Card>
              </Column>
              <Column>
                <Card>
                  <img src={MRHF9} alt='MRHF' />
                </Card>
              </Column>
              <Column>
                <Card>
                  <img src={heroImg} alt='MRHF' />
                </Card>
              </Column>
            </ThreeColumnContainer>
            <ThreeColumnContainer>
              <Column>
                <Card>
                  <img src={MRHF5} alt='MRHF' />
                </Card>
              </Column>
              <Column>
                <Card>
                  <img src={MRHF10} alt='MRHF' />
                </Card>
              </Column>
              <Column>
                <Card>
                  <img src={MRHF4} alt='MRHF' />
                </Card>
              </Column>
            </ThreeColumnContainer>
          </Container>

          <SectionContainer id='donate'>
            <SectionHeading>Donate to MRHF</SectionHeading>
            <SectionDescription>
              <div
                id='iframe-container'
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                  width: '100%',
                  minHeight: '2000px',
                }}
              >
                <iframe
                  title='Donation form powered by Zeffy'
                  style={{
                    position: 'absolute',
                    border: '0',
                    top: '0',
                    left: '0',
                    bottom: '0',
                    right: '0',
                    width: '100%',
                    height: '100%',
                  }}
                  src='https://www.zeffy.com/en-CA/embed/donation-form/a12cd4ab-1082-4c5f-8402-d3fe0b6fb736'
                  allowpaymentrequest
                  allowTransparency='true'
                ></iframe>
              </div>
            </SectionDescription>
          </SectionContainer>
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};
